<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white task-duplication">
      <div class="close-btn-bg">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto">Move task</span>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        Workspace
      </div>
      <div class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedWorkspace && selectedWorkspace.title"
        >
          <q-menu content-class="workspace-menu" auto-close>
            <div class="search-member">
              <q-input
                autofocus
                ref="search"
                v-model="search"
                outlined
                placeholder="Filter workspaces"
                clearable
                dense
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
              </q-input>
            </div>
            <q-list style="min-width:140px; max-height: 250px; overflow: auto">
              <q-item
                @click="chooseWorkspace(workspace)"
                v-for="workspace in filteredWorkspaces"
                :key="workspace.id"
                clickable
              >
                <q-item-section>
                  {{ workspace.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        Column
      </div>
      <div v-if="cardList" class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedCard && selectedCard.title"
        >
          <q-menu content-class="overflow-auto" auto-close>
            <q-list style="min-width:140px">
              <q-item
                @click="chooseCard(card)"
                v-for="card in cardList"
                :key="card.id"
                clickable
              >
                <q-item-section>
                  {{ card.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          padding="5px 5px"
          @click="closeDialog"
        />
        <q-btn
          dense
          no-caps
          padding="5px 5px"
          class="create-task-btn"
          color="primary"
          size="md"
          label="Move Task"
          @click="createTaskHandler"
          :loading="isLoading"
          :disable="!this.selectedCard"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import pick from "lodash/pick";
import head from "lodash/head";
import map from "lodash/map";

import getUnixTime from "date-fns/getUnixTime";

import {
  UpdateTaskMutation,
  MoveTaskMutation,
  DeleteTaskMutation,
} from "@/gql";
import Fuse from "fuse.js";

export default {
  name: "MoveTaskDialog",
  props: ["value", "task", "closeDialog", "board"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      selectedWorkspace: null,
      selectedCard: null,
      cardList: null,
      isLoading: false,
      taskData: {
        title: null,
        start_date: null,
        end_date: null,
        includeDescription: true,
        includeSubtasks: true,
        includeAttachments: true,
      },
      search: null,
    };
  },
  mounted() {
    this.taskData.title = this.task.title;
    this.taskData.start_date = this.task.start_date;
    this.taskData.end_date = this.task.end_date;
    this.selectedWorkspace = this.currentWorkspace;
    this.cardList = this.currentWorkspace.boards[0].cards;
    this.selectedCard = this.cardList[0];
  },
  methods: {
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.cardList = workspace.boards[0].cards;
      this.selectedCard = this.cardList[0];
    },
    chooseCard(card) {
      this.selectedCard = card;
    },
    createTaskHandler() {
      this.isLoading = true;
      const task = {
        title: this.taskData.title,
        card_id: this.selectedCard.id,
        board_id: this.selectedWorkspace.boards[0].id,
        start_date: this.taskData.start_date,
        end_date: this.taskData.end_date,
        details: null,
        media: [],
        member: [],
        subtask: [],
        note: this.task.note ? this.task.note.note : null,
        is_lock: this.task.is_lock,
        is_invisible: this.task.is_invisible,
      };
      if (this.taskData.includeDescription) {
        task.details = this.task.details;
      }
      if (
        this.taskData.includeSubtasks &&
        this.task.subtasks &&
        this.task.subtasks.length
      ) {
        const subtasks = map(this.task.subtasks, (subtask) => {
          const subtaskMedia = subtask.media.map((s) => s.id);
          const subtaskObj = {
            title: subtask.title,
            user_id: this.user.id,
            sequence: subtask.sequence,
            media: subtaskMedia,
          };
          
          if(subtask.childSubtasks) {
            const childSubtask = map(subtask.childSubtasks, (childSub) => {
              const childMedia = childSub.media.map((s) => s.id);
              const child = {
                title: childSub.title,
                user_id: this.user.id,
                sequence: childSub.sequence,
                media: childMedia,
              }
              return child;
            });
            subtaskObj.childSubtasks = childSubtask;
          }
          
          return subtaskObj;
        });
        task.subtask = subtasks;
      }
      if (
        this.taskData.includeAttachments &&
        this.task.media &&
        this.task.media.length
      ) {
        const mediaIds = map(this.task.media, (o) => o.id);
        task.media = mediaIds;
      }
      this.addTask(task);
    },
    async addTask(task) {
      const cardTasks = this.cardTasks[task.card_id];
      const activeTasks = cardTasks
        ? cardTasks.filter((task) => !task.completed)
        : cardTasks;
      const activeTasksOrdered = activeTasks
        ? orderBy(activeTasks, ["sequence"], ["asc"])
        : activeTasks;
      let sequence;
      if (activeTasksOrdered === undefined) {
        sequence = activeTasksOrdered
          ? RandomPosition.between(
              RandomPosition.first(),
              head(activeTasksOrdered).sequence
            )
          : RandomPosition.between(
              RandomPosition.first(),
              RandomPosition.last()
            );
      } else {
        sequence =
          activeTasksOrdered.length > 0
            ? RandomPosition.between(
                RandomPosition.first(),
                head(activeTasksOrdered).sequence
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
      }
      const variables = {
        ...task,
        sequence,
      };
      const taskOwner = pick(this.user, [
        "id",
        "first",
        "last",
        "pic",
        "username",
      ]);
      const fakeTask = {
        __typename: "task",
        id: getUnixTime(new Date()),
        title: task.title,
        isbookmarked: false,
        details: task.details,
        completed: false,
        end_date: null,
        sequence: sequence,
        card_id: task.card_id,
        last_label: "notstarted",
        members: [],
        owner: {
          ...taskOwner,
          __typename: "user",
        },
        labels: task.labels,
        subtasks: [],
      };
      await this.$api
        .mutate({
          mutation: MoveTaskMutation,
          variables,
        })
        .then(async (response) => {
          const boardQuery = this.$api.getQuery(
            `BoardQuery:${this.selectedWorkspace.boards[0].id}`
          );
          if (boardQuery.data) {
            boardQuery.data.board.tasks.push(fakeTask);
            boardQuery.data.board.tasks = boardQuery.data.board.tasks.map(
              (task) => (task === fakeTask ? response.data.moveTask : task)
            );
          }
          if (
            this.taskData.includeAttachments &&
            this.task.media &&
            this.task.media.length
          ) {
            if (this.task.cover_image) {
              this.updateCoverImage(
                this.task.cover_image,
                response.data.moveTask.id
              );
            }
          }

          const variables = {
            id: this.task.id,
          };

          await this.$api.mutate({
            mutation: DeleteTaskMutation,
            variables,
          });

          // if (this.isPrivateTask) {
          //   const privateTasksQuery = this.$api.getQuery(
          //     `PrivateTasksQuery:${this.board.id}`
          //   );
          //   privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
          //     (task) => task.id !== id
          //   );
          // }

          const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);

          query.data.board.tasks = query.data.board.tasks.filter(
            (task) => task.id !== this.task.id
          );

          this.isLoading = false;
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Task moved successfully.",
            icon: this.$icons.matAnnouncement,
          });
          this.closeDialog();
        });
    },
    async updateTask(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables,
      });
    },
    updateCoverImage(mediaId, taskId) {
      const task = this.$api.getEntity("task", taskId);
      task.cover_image = mediaId;
      this.updateTask({
        id: taskId,
        cover_image: mediaId,
      });
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    cardTasks: {
      get() {
        return groupBy(
          orderBy(this.selectedWorkspace.boards[0].tasks, ["sequence"]),
          "card_id"
        );
      },
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 25px 0px 25px;
}
</style>
