<template>
	<q-dialog :seamless="!maximizedToggle" :value="true" persistent :maximized="maximizedToggle"  :content-class="{minimized: !maximizedToggle, 'video-call': true, 'fullscreen-window':fullscreen}">
		<q-card class="column no-wrap video-call-dialog" :class="{ dialog: true, minimized: !maximizedToggle }">
			<q-bar style="background: #283949;color: #fff;height: 35px;" class="q-py-md">
				<q-space />
				<q-btn dense flat :icon="$icons.mdiWindowMinimize" @click="maximizedToggle = !maximizedToggle, fullscreen = false" :disable="!maximizedToggle">
					<q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
				</q-btn>
				<q-btn dense flat :icon="$icons.matFullscreenExit" @click="maximizedToggle = true, fullscreen = false" :disable="maximizedToggle">
					<q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
				</q-btn>
				<q-btn dense flat :icon="$icons.matCropSquare" @click="fullscreen = true, maximizedToggle = false;" :disable="fullscreen">
					<q-tooltip v-if="!fullscreen"  class="bg-white text-primary">Full screen</q-tooltip>
				</q-btn>
				<q-btn dense flat :icon="$icons.matClose" @click="closeDialog">
					<q-tooltip class="bg-white text-primary">Close</q-tooltip>
				</q-btn>
			</q-bar>
			<q-card-section class="inner-card">
				<div
					v-if="isLoading"
					class="column items-center justify-center"
					style="height:500px;"
				>
					<q-circular-progress
						indeterminate
						:thickness="0.1"
						size="75px"
						color="primary"
						class="q-ma-md"
					/>
				</div>
				<!-- <div class="close" style="position: absolute;right: 0px;" @click="closeDialog">
					<div class="icon">
						<q-icon
							:name="$icons.matClose"
							size="20px"
							style="cursor: pointer"
						></q-icon>
					</div>
				</div> -->
				<div id="heycollab-participant-meet">
				</div>
			</q-card-section>
		</q-card>
	</q-dialog>
</template>

<script>
export default {
	props: ["roomName", "jwtToken"],
	data() {
		return {
			minimized: false,
			isLoading: true,
			dialog: false,
			maximizedToggle: true,
			fullscreen:false,
		};
	},
	api: {
		// user: {
		// 	cacheKey: "UserQuery",
		// 	defaultValue: null,
		// },
		// company: {
		// 	cacheKey: "CompanyQuery",
		// 	defaultValue: null,
		// },
	},
	components: {
		// VideoDialog,
		// UpgradePlansDialog,
	},
	methods: {
		closeDialog() {
			this.$emit("closeDialog", null);
		},
	},
	computed: {
	},
	mounted() {
		this.$nextTick(() => {
				const domain = "8x8.vc";
				const roomName = this.roomName;
				const options = {
					jwt: this.jwtToken,
					roomName: `${process.env.VUE_APP_MEET_API_KEY}/${roomName}`,
					width: '100%',
					height: '100%',
					configOverwrite: {
						brandingRoomAlias: roomName,
						inviteDomain: process.env.VUE_APP_MEET_URL,
					},
					parentNode: document.querySelector('#heycollab-participant-meet'),
				};
        		
				let api = new window.JitsiMeetExternalAPI(domain, options);	
				
				this.isLoading = false;
				
				if (window.setupScreenSharingRender) {
					window.setupScreenSharingRender(api);
				}

				api.addEventListener('participantRoleChanged', function (event) {
					if(event.role === 'moderator') {
						api.executeCommand('toggleLobby', true);
					}
				});

				api.addEventListener('readyToClose', () => {
					this.closeDialog();
				});
		});
	},
};
</script>
<style>
.video-call.fullscreen-window {
	z-index: 6001;
	width: 99%!important;
	max-width: 100%!important;
	height: 99%!important;
	max-height: 100%!important;
	overflow: hidden!important;
}

.video-call.fullscreen {
	z-index: 6001;
}

.video-call.fullscreen-window .video-call-dialog {
	width: 99%!important;
    max-width: 100%!important;
    height: 99%!important;
    max-height: 100%!important;
    overflow: hidden!important;
}
.video-call-dialog {
	width: calc(100vw - 100px)!important;
    max-width: 1150px!important;
    height: 56.25vw!important;
    max-height: 596.875px!important;
    overflow: hidden!important;
}
#heycollab-participant-meet{
	min-height: 95%;
	height: 95%;
}
.dialog {
  position: absolute;
}
.dialog .q-card__section--vert {
	padding:0px;
}
.dialog.minimized {
  top: auto;
  bottom: 10px;
  right: 10px;
  left: auto;
  border-radius: 0px!important;
  width: 300px!important;
  height: 35px!important;
}

.q-dialog.minimized .q-dialog__backdrop {
	background: transparent;
	top: auto;
	bottom: 10px;
	right: 10px;
	left: auto;
	width: 300px!important;
	height: 200px!important;
	position: absolute;
  }
.q-dialog.minimized{
	z-index: 1001;
  }
.inner-card{
	min-height: 100%;
    height: 100%;
}
</style>
