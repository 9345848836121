<template>
  <div
    class="hide-expansion-hover card-list-item-toggle-arrow q-mb-md"
    :class="[!card.id && 'static']"
    :id="card.id"
  >
    <q-expansion-item
      @input="changeExpansionState"
      :value="isCardExpanded"
      default-opened
      expand-icon-toggle
      switch-toggle-side
      :key="card.id"
    >
      <template v-slot:header>
        <div
          class="row items-center card-list-item-title justify-between flex-1"
        >
          <div class="row flex-1 group-hover" v-if="!card.id">
            <div>
              <div class="q-ml-sm board-cardtitle-ce text-h6">
                {{ card.title }} {{ activeTaskCount }}
              </div>
            </div>
            <!-- <div
              v-if="!editorFocused && expansinoState"
              class="group-hover-item q-ml-md"
            >
              <q-btn
                flat
                class="add-list-btn"
                dense
                no-caps
                @click.stop="addNewColumnFromColumn(card)"
              >
                <q-icon size="16px" class="plus-icon" :name="$icons.matAdd" />
                <span style="font-size:11px">
                  Add list
                </span>
              </q-btn>
            </div> -->
            <!-- <div
              v-if="!editorFocused && expansinoState"
              class="q-ml-md group-hover-item"
            >
              <q-btn
                flat
                class="add-task-btn q-mr-md"
                dense
                no-caps
                @click.stop="
                  () => {
                    addTaskSection = true;
                  }
                "
              >
                <q-icon size="16px" class="plus-icon" :name="$icons.matAdd" />
                <span style="font-size:11px">
                  Add Task
                </span>
              </q-btn>
            </div> -->
          </div>
          <div class="row flex-1 group-hover" v-else>
            <div
              :class="[
                editorFocused && 'flex-1',
                'row items-center flex-no-wrap',
              ]"
            >
              <div
                v-if="!editorFocused"
                @click.stop="editorFocused = true"
                class="q-ml-sm board-cardtitle-ce text-h6"
                :style="{
                  color:
                    card.color && card.color !== '#172b4d'
                      ? card.color
                      : '#172b4d',
                }"
              >
                {{ card.title }} {{ activeTaskCount }}
              </div>
              <q-input
                v-else
                dense
                padding="0"
                outlined
                :value="card.title"
                @change="updateCardTitle"
                class="board-cardtitle-ce text-h6 q-ml-sm"
                bg-color="white"
                input-class="text-h6"
                @blur="editorFocused = false"
                @keydown.enter.exact="updateCardTitle"
                @click.stop
                autofocus
              />
              <!-- <div
                v-if="!editorFocused && expansinoState"
                class="q-ml-md group-hover-item"
              >
                <q-btn
                  flat
                  class="add-task-btn q-mr-md"
                  dense
                  no-caps
                  @click.stop="
                    () => {
                      addTaskSection = true;
                    }
                  "
                >
                  <q-icon size="16px" class="plus-icon" :name="$icons.matAdd" />
                  <span style="font-size:11px">
                    Add Task
                  </span>
                </q-btn>
              </div> -->
              <!-- <div
                v-if="!editorFocused && expansinoState"
                class="group-hover-item"
              >
                <q-btn
                  flat
                  class="add-list-btn"
                  dense
                  no-caps
                  @click.stop="addNewColumnFromColumn(card)"
                >
                  <q-icon size="16px" class="plus-icon" :name="$icons.matAdd" />
                  <span style="font-size:11px">
                    Add list
                  </span>
                </q-btn>
              </div> -->
            </div>
          </div>
          <div v-if="card.id">
            <q-btn
              @click.stop
              flat
              :icon="$icons.matMoreHoriz"
              size="md"
              padding="0"
              round
            >
              <q-tooltip> More </q-tooltip>
              <q-menu content-class="overflow-auto">
                <q-list>
                  <q-item clickable>
                    <q-item-section> Change list color </q-item-section>
                    <q-item-section side>
                      <q-icon :name="$icons.matKeyboardArrowRight" />
                    </q-item-section>
                    <q-menu
                      anchor="top end"
                      self="center start"
                      max-height="auto"
                      content-class="list-color-swatch-container row q-py-md q-px-md"
                      auto-close
                    >
                      <div
                        v-for="data in swatchColors"
                        :key="'swathc-' + data.id"
                        class="row flex-no-wrap relative-position"
                        @click="updateListColor(data.colorcode)"
                      >
                        <div
                          :class="[
                            data.isWhite && 'white-box',
                            'swatch-box cursor-pointer',
                            isSwatchSelected(data.colorcode)
                              ? 'selected'
                              : 'not-selected',
                          ]"
                          :style="{
                            'background-color': data.colorcode,
                          }"
                        ></div>
                        <q-icon
                          v-if="isSwatchSelected(data.colorcode)"
                          :name="$icons.fasCheck"
                          color="white"
                          size="15px"
                          class="swatch-selected-check"
                        />
                      </div>
                    </q-menu>
                  </q-item>
                  <q-separator />
                  <q-item
                    clickable
                    @click="deleteCardDeleteConfirmBoxHandler"
                    class="text-red"
                  >
                    <q-item-section> Delete entire list </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </template>
      <div class="q-pl-md">
        <div
          v-sortable="{
            group: 'mytask',
            onAdd: onDragAddOrUpdate,
            onUpdate: onDragAddOrUpdate,
            emptyInsertThreshold: 100,
            onStart: onDragStart,
            sort: card.isPrivateTask ? false : true,
          }"
        >
          <div
            class="q-mb-xs"
            v-for="task in filteredTodos"
            :key="task.id"
            :data-id="task.id"
          >
            <task-list-item
              v-if="
                !task.completed && getWorkspace(task.myworks[0].workspace_id)
              "
              :taskdata="task"
              :labels="labels"
              :card="card"
              @delete="deleteTask"
              :board="getBoard(task.board_id)"
              :boardId="task.board_id"
              :workspace="getWorkspace(task.myworks[0].workspace_id)"
              place="board"
              :workspaceMembers="
                getWorkspaceMember(task.myworks[0].workspace_id)
              "
              :editTaskDialogHandler="editTaskDialogHandler"
              :onUpdateTaskMembersHandler="onUpdateTaskMembersHandler"
              :showWorkspace="true"
              :isFromMyworks="true"
              :userId="userId"
            />
          </div>
        </div>
        <!-- <div>
          <div
            @click="
              () => {
                addTaskSection = true;
              }
            "
            class="add-task-area text-center row justify-center items-center cursor-pointer q-mt-sm"
            style="height:42px"
          >
            <q-icon :name="$icons.matAdd" style="color:42526E" />
            <div class="q-ml-sm">Add a task</div>
          </div>
        </div> -->
      </div>
    </q-expansion-item>
    <confirm-dialog
      v-if="cardRemoveConfirm.flag"
      v-model="cardRemoveConfirm.flag"
      title="Delete Column?"
      question="Deleting this column will also delete all the tasks in it."
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetCardDeleteConfirmBox"
      :successMethod="deleteCard"
      :loading="false"
    />
    <!--Clear Complete Task Dialog -->
    <confirm-dialog
      v-if="clearCompletedTaskConfirm.flag"
      v-model="clearCompletedTaskConfirm.flag"
      title="Delete Completed Tasks?"
      question="Are you sure you want to delete all completed tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearCompletedTaskConfirmBox"
      :successMethod="clearCompletedTaskFromColumn"
      :loading="false"
    />

    <!--Clear Active Task Dialog -->
    <confirm-dialog
      v-if="clearActiveTaskConfirm.flag"
      v-model="clearActiveTaskConfirm.flag"
      title="Delete Active Tasks?"
      question="Are you sure you want to delete all active tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearActiveTaskConfirmBox"
      :successMethod="clearActiveTaskFromColumn"
      :loading="false"
    />
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :taskLabels="labels"
      :userId="userId"
      :showWorkspaceSection="true"
      sequencePosition="top"
    />
  </div>
</template>
<script>
import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import TaskListItem from "@/views/BoardView/TaskListItem.vue";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";

import { UpdateMyTaskMutation, UpdateCustomCardColorMutation } from "@/gql";
import { swatchColors } from "@/utils/Contents.js";

import mixpanel from "@/mixpanel";
export default {
  name: "MyCardListItem",
  props: [
    "card",
    "board",
    "labels",
    "workspaceMembers",
    "tasks",
    "workspace",
    "editTaskDialogHandler",
    "addTaskEventHandler",
    "onUpdateTaskMembersHandler",
    "addNewColumnFromColumn",
    "userId",
  ],
  components: {
    ConfirmDialog,
    TaskListItem,
    NewTaskDialog,
  },
  data() {
    return {
      expansinoState: true,
      cardRemoveConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearCompletedTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearActiveTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      addingTask: false,
      addTaskSection: false,
      editorFocused: false,
      swatchColors,
    };
  },
  mounted() {
    const cardExpansionState = this.$store.getters.isMyWorkCardExpanded(
      this.userId,
      this.card.id
    );
    if (!cardExpansionState) {
      this.$store.dispatch("setMyWorkCardExpansionState", {
        cardId: this.card.id,
        userId: this.userId,
        flag: true,
      });
    }
  },
  methods: {
    async updateListColor(color) {
      this.card.color = color;
      await this.$api.mutate({
        mutation: UpdateCustomCardColorMutation,
        variables: {
          user_card_id: this.card.id,
          color,
        },
      });
    },
    isSwatchSelected(color) {
      return (
        this.card.color && this.card.color.toLowerCase() === color.toLowerCase()
      );
    },
    changeExpansionState() {
      this.$store.dispatch("setMyWorkCardExpansionState", {
        cardId: this.card.id,
        userId: this.userId,
        flag: !this.isCardExpanded,
      });
    },
    onDragStart() {
      if (this.card.isPrivateTask) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Tasks in My private tasks list are not sortable",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const task = this.$api.getEntity("task", item.dataset.id);
      const tmp = this.filteredTodos ? [...this.filteredTodos] : [];

      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, task);
      if (this.card.id) {
        task.myworks[0].user_card_id = this.card.id;
      } else {
        task.myworks[0].user_card_id = null;
      }
      const index = task.myworks.findIndex(
        (w) => parseInt(w.user_id) === this.userId
      );
      task.myworks[index].user_sequence = RandomPosition.between(
        tmp[newIndex - 1]
          ? tmp[newIndex - 1].myworks[0].user_sequence
          : RandomPosition.first(),
        tmp[newIndex + 1]
          ? tmp[newIndex + 1].myworks[0].user_sequence
          : RandomPosition.last()
      );
      task.completed = false;
      this.$api.mutate({
        mutation: UpdateMyTaskMutation,
        variables: {
          task_id: task.id,
          user_sequence: task.myworks[0].user_sequence,
          user_card_id: this.card.id ? this.card.id : null,
          user_id: this.userId,
        },
      });
      mixpanel.track("Task Move");
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
    },
    getBoard(boardId) {
      return this.$api.getEntity("board", boardId);
    },
    getWorkspace(workspaceId) {
      return this.$api.getEntity("workspace", workspaceId);
    },
    getWorkspaceMember(workspaceId) {
      const workspaceData = this.$api.getEntity("workspace", workspaceId);
      if (workspaceData) {
        return workspaceData.users;
      }
      return null;
    },
    updateCardTitle(event) {
      this.editorFocused = false;
      const title = event.target.value.trim();
      this.$emit("updateCard", {
        id: this.card.id,
        title: title,
        sequence: this.card.sequence,
      });
    },
    resetCardDeleteConfirmBox() {
      this.cardRemoveConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    deleteCardDeleteConfirmBoxHandler() {
      this.cardRemoveConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    async deleteCard() {
      await this.$emit("deletCard", this.card);
      this.resetCardDeleteConfirmBox();
    },
    resetClearCompletedTaskConfirmBox() {
      this.clearCompletedTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearCompletedTaskConfirmBoxHandler() {
      this.clearCompletedTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearCompletedTaskFromColumn() {
      this.$emit(
        "clearCompletedTaskFromColumn",
        this.clearCompletedTaskConfirm.id
      );
      this.resetClearCompletedTaskConfirmBox();
    },
    resetClearActiveTaskConfirmBox() {
      this.clearActiveTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearActiveTaskConfirmBoxHandler() {
      this.clearActiveTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearActiveTaskFromColumn() {
      this.$emit("clearActiveTaskFromColumn", this.clearActiveTaskConfirm.id);
      this.resetClearActiveTaskConfirmBox();
    },
    addTaskEventMyHandler(data) {
      this.addingTask = true;
      this.addTaskEventHandler(data, () => {});
      setTimeout(() => {
        this.addingTask = false;
      }, 300);
    },
    onBlurCloseAddingTask(value) {
      setTimeout(() => {
        if (!this.addingTask) {
          this[value] = false;
        }
      }, 200);
    },
    deleteTask(task) {
      this.$emit("deleteTask", task);
    },
  },
  computed: {
    filteredTodos: {
      get() {
        const taskGroup = orderBy(this.tasks, "completed");
        return taskGroup;
      },
    },
    activeTaskCount() {
      return this.filteredTodos.filter((t) => !t.completed).length;
    },
    isCardExpanded() {
      if (this.$store.getters.isMyWorkCardExpanded(this.userId, this.card.id)) {
        return this.$store.getters.isMyWorkCardExpanded(
          this.userId,
          this.card.id
        ).isExpanded;
      }
      return true;
    },
  },
};
</script>
