<template>
  <td style="min-width:95px" class="row justify-center task-column-item">
    <div class="row justify-center items-center">
      <div
        v-if="taskdata.start_date"
        :class="[
          'task-due-date cursor-pointer',
          isEndDateDue,
        ]"
        class="custom-task-tag relative-position"
      >
        <!-- <div class="close" @click.stop="save(null)">
        <q-icon :name="$icons.matClose" />
      </div> -->
        {{ taskdata.start_date | dateMonthDateDefault }}
        <q-tooltip>
          Due Date
        </q-tooltip>
      </div>
      <div
        v-else-if="taskdata.end_date"
        :class="[
          'task-due-date cursor-pointer',
          isStartDateDue,
        ]"
        class="custom-task-tag relative-position"
      >
        {{ taskdata.end_date | dateMonthDateDefault }}
        <q-tooltip>
          Due Date
        </q-tooltip>
      </div>
    </div>
  </td>
</template>

<script>
import { isPast } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export default {
  props: ["taskdata"],
  computed: {
    isStartDateDue() {
      if(format(new Date(this.taskdata.end_date), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
        return "due-date-today";
      }else if(isPast(parseISO(this.taskdata.end_date))) {
        return "overdue-date";
      }
      return "due-date";
    },
    isEndDateDue() {
      if(format(new Date(this.taskdata.start_date), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
        return "due-date-today";
      }else if(isPast(parseISO(this.taskdata.start_date))) {
        return "overdue-date";
      }
      return "due-date";
    },
  },
};
</script>
