<template>
  <tr
    v-if="task"
    style="min-width:1160px;"
    class="hide-on-drag task-list-item task-timer row flex-no-wrap items-center group-hover relative-position q-pr-sm"
    :class="isMenuOpen && 'task-list-item-active'"
  >
    <td
      :class="[
        'row task-column-item items-center q-pl-sm task-column-item-titel',
      ]"
      style="min-width:335px;max-width:350px;width: 100%;"
    >
      <div style="z-index:1" class="q-mr-sm">
        <div
          @click="changeCompleteStatus"
          v-if="cardTask.completed"
          class="cursor-pointer"
        >
          <div class="row items-center flex-no-wrap">
            <div class="task-icon relative-position">
              <q-icon
                class="absolute text-bold"
                :name="$icons.fasCheck"
                style="color:#15d89a"
                size="15px"
              />
            </div>
          </div>
          <q-tooltip>
            Mark Task InComplete
          </q-tooltip>
        </div>
        <div
          @click="changeCompleteStatus"
          v-else
          class="cursor-pointer notification-sidebar-outline-dot "
          :style="{
            'border-color':
              card && card.color && card.color !== '#172b4d'
                ? card.color
                : '#b1bcc9',
          }"
        >
          <q-tooltip>
            Mark Task Complete
          </q-tooltip>
        </div>
      </div>
      <div
        @click="edit(cardTask, 'taskComment')"
        class="flex-1 cursor-pointer  q-mr-xs row items-center flex-no-wrap"
        :class="[cardTask.completed && 'strike']"
        :id="'listItem' + cardTask.id"
      >
      <q-menu
          auto-close
          context-menu
          content-class="menu-hover overflow-auto q-pa-sm"
          v-model="isMenuOpen"
        >
          <q-list>
            <q-item
              @click="
                $copyTextToClipboard(copyTaskLink);
                isTaskCopy = true;
              "
              clickable
            >
              <q-item-section>Copy task link</q-item-section>
            </q-item>
            <q-item
              v-if="isVisible([1, 2, 3]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
              clickable
              @click="moveTask.flag = true"
            >
              <q-item-section>Move task</q-item-section>
            </q-item>
            <q-item
              v-if="isVisible([1, 2, 3])"
              clickable
              @click="cloneTask.flag = true"
            >
              <q-item-section>Duplicate task</q-item-section>
            </q-item>
            <q-separator
              v-if="isVisible([1, 2, 3])"
              class="q-my-sm"
              style="background:#E3E8ED;"
            />
            <!-- <q-item @click="edit(cardTask)" clickable>
              <q-item-section>Edit task</q-item-section>
            </q-item> -->
            <q-item
              @click="changeCompleteStatus"
              clickable
              v-if="place === 'board' && isVisible([1, 2, 3])  && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
            >
              <q-item-section v-if="!cardTask.completed"
                >Complete task</q-item-section
              >
              <q-item-section v-else>Mark as incomplete</q-item-section>
            </q-item>
            <q-item
              v-if="isVisible([1, 2, 3])"
              @click="openTimelogDialogHendler"
              clickable
            >
              <q-item-section
                v-if="taskdata && taskdata.timelogs && taskdata.timelogs.length"
                >View time report</q-item-section
              >
              <q-item-section v-else>Add time report</q-item-section>
            </q-item>
            <q-item
              @click="changeTaskLock(true)"
              clickable

              v-if="task.owner.id === user.id && !taskdata.is_lock"
            >
              <q-item-section>Lock task</q-item-section>
            </q-item>
            <q-item
              @click="changeTaskLock(false)"
              clickable
              v-if="task.owner.id === user.id && taskdata.is_lock"
            >
              <q-item-section>Unlock task</q-item-section>
            </q-item>
            <q-item
              @click="changeTaskVisible(true)"
              clickable
              v-if="task.owner.id === user.id && !taskdata.is_invisible"
            >
              <q-item-section>Make task invisible</q-item-section>
            </q-item>
            <q-item
              @click="changeTaskVisible(false)"
              clickable
              v-if="task.owner.id === user.id && taskdata.is_invisible"
            >
              <q-item-section>Make task visible</q-item-section>
            </q-item>
            <q-item
              class="text-red"
              @click="$emit('delete', cardTask)"
              clickable
              v-if="isVisible([1, 2, 3]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
            >
              <q-item-section>Delete task</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <div class="task-ticket-number q-mr-xs" style="white-space:nowrap">
          T{{ workspace.title.substring(0, 2).toUpperCase() }}-{{ task.id }}
        </div>
        <div
          class="dont-break-out ellipses an-15 medium-text"
          :id="'listItem_title' + cardTask.id"
          v-tooltip
        >
          {{ cardTask.title }}
          <q-tooltip v-if="isTruncated">
            {{ cardTask.title }}
          </q-tooltip>
          <q-tooltip v-else-if="cardTask.owner" >
            Created by {{ cardTask.owner.first | capitalize }}
            {{ cardTask.owner.last | capitalize }}
          </q-tooltip>
          <q-btn
              flat
              dense
              size="8px"
              round
              padding="0"
              style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
              :icon="$icons.matLock"
              class="q-ml-xs"
              v-if="task.is_lock"
            >
              <!-- <q-tooltip>
                Task locked by {{ taskdata.owner.first | capitalize }} {{ taskdata.owner.last | capitalize }}
              </q-tooltip> -->
            </q-btn>
            <q-btn
              flat
              dense
              size="8px"
              round
              padding="0"
              style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
              :icon="$icons.matVisibilityOff"
              v-if="task.is_invisible"
              class="q-ml-xs"
            >
              <!-- <q-tooltip>
                Task invisible by {{ taskdata.owner.first | capitalize }} {{ taskdata.owner.last | capitalize }}
              </q-tooltip> -->
            </q-btn>
        </div>
        <div v-if="hasNotifications.length">
          <q-icon
            color="pink"
            size="8px"
            :name="$icons.matFiberManualRecord"
            style="margin-top:-5px"
          />
        </div>
      </div>
    </td>
    <template v-if="!showWorkspace">
      <template v-for="column in columnFiledList">
        <div :key="column.id">
          <invite-task-member-cell
            v-if="column.title === 'Assigned to' && column.isvisible"
            :taskdata="taskdata"
            :isWorkspaceOwner="isWorkspaceOwner"
            :task="task"
            :workspace="workspace"
          />
          <due-date-cell
            v-else-if="column.title === 'Due date' && column.isvisible"
            :taskdata="taskdata"
          />
          <timer-cell
            v-else-if="column.title === 'Tracked time' && column.isvisible"
            :cardTask="cardTask"
            :taskdata="taskdata"
            :task="task"
            :workspace="workspace"
            :user="user"
            :board="board"
            @openTimelogDialog="openTimelogDialogHendler"
          />
          <td
            v-else-if="column.title === 'Subtasks' && column.isvisible"
            style="min-width:100px"
            class="row justify-center task-column-item"
          >
            <div class="row justify-center items-center">
              <q-btn
                flat
                dense
                size="8px"
                style="color:#B1BCC8;"
                padding="0"
                :icon="$icons.fasCheck"
                :class="!cardTask.subtasks.length && 'card-options'"
                @click="edit(cardTask, 'openSubtask')"
              >
                <span
                  v-if="cardTask.subtasks.length"
                  class="an-13 medium-text q-ml-xs"
                  >{{ completedSubtasks }}/{{ cardTask.subtasks.length }}</span
                >
              </q-btn>
            </div>
          </td>
          <td
            v-else-if="column.title === 'Tags' && column.isvisible"
            style="min-width:150px"
            class="row justify-center task-column-item"
            :style="!showWorkspace && { border: 'none' }"
          >
            <div class="row justify-around items-center">
              <div class="relative-position list-label q-ml-sm">
                <template v-for="(item, index) in task.labels">
                  <q-btn
                    :key="item.id"
                    rounded
                    :class="[
                      index !== task.labels.length - 1 && ' q-mr-xs',
                      'q-my-xs',
                    ]"
                    :style="{ 'background-color': item.colorcode }"
                  >
                    <div
                      class="remove-label"
                      @click.stop="removeLabel(item, task)"
                    >
                      <q-icon
                        class="absolute"
                        style="left:0"
                        size="7px"
                        :name="$icons.matClose"
                      />
                    </div>
                    <q-tooltip
                      :content-style="{
                        'background-color': item.colorcode,
                      }"
                    >
                      {{ item.name }}
                    </q-tooltip>
                  </q-btn>
                </template>
              </div>
            </div>
          </td>
          <custom-field-task-list-cell
            v-else-if="column.c_f_id && column.isvisible"
            :taskdata="taskdata"
            :column="column"
          />
        </div>
      </template>
    </template>
    <template v-else>
      <invite-task-member-cell
        :taskdata="taskdata"
        :isWorkspaceOwner="isWorkspaceOwner"
        :task="task"
        :workspace="workspace"
      />
      <due-date-cell :taskdata="taskdata" />
      <timer-cell
        :cardTask="cardTask"
        :taskdata="taskdata"
        :task="task"
        :workspace="workspace"
        :user="user"
        :board="board"
        @openTimelogDialog="openTimelogDialogHendler"
      />
      <td style="min-width:100px" class="row justify-center task-column-item">
        <div class="row justify-center items-center">
          <q-btn
            flat
            dense
            size="8px"
            style="color:#B1BCC8;"
            padding="0"
            :icon="$icons.fasCheck"
            :class="!cardTask.subtasks.length && 'card-options'"
            @click="edit(cardTask, 'openSubtask')"
          >
            <span
              v-if="cardTask.subtasks.length"
              class="an-13 medium-text q-ml-xs"
              >{{ completedSubtasks }}/{{ cardTask.subtasks.length }}</span
            >
          </q-btn>
        </div>
      </td>
      <td
        style="min-width:150px"
        class="row justify-center task-column-item"
        :style="!showWorkspace && { border: 'none' }"
      >
        <div class="row justify-around items-center">
          <div class="relative-position list-label q-ml-sm">
            <template v-for="(item, index) in task.labels">
              <q-btn
                :key="item.id"
                rounded
                :class="[
                  index !== task.labels.length - 1 && ' q-mr-xs',
                  'q-my-xs',
                ]"
                :style="{ 'background-color': item.colorcode }"
              >
                <div class="remove-label" @click.stop="removeLabel(item, task)">
                  <q-icon
                    class="absolute"
                    style="left:0"
                    size="7px"
                    :name="$icons.matClose"
                  />
                </div>
                <q-tooltip
                  :content-style="{
                    'background-color': item.colorcode,
                  }"
                >
                  {{ item.name }}
                </q-tooltip>
              </q-btn>
            </template>
          </div>
        </div>
      </td>
    </template>
    <td
      style="min-width:200px;"
      class="row justify-center"
      v-if="showWorkspace"
    >
      <div class="row justify-around items-left">
        <div class="q-pa-md">
          <div
            class="text-truncate cursor-pointer"
            style="max-width: 165px; color: #1f89fc"
            @click="
              $router.push({
                name: 'BoardView',
                params: {
                  company: $route.params.company,
                  workspace: workspace.id,
                },
              })
            "
            v-tooltip
          >
            {{ workspace.title }}
            <q-tooltip v-if="isTruncated">
              {{ workspace.title }}
            </q-tooltip>
          </div>
        </div>
      </div>
    </td>
    <div class="task-list-bookmark">
      <q-icon
        v-if="cardTask.isbookmarked"
        @click="removeTaskBookmark(cardTask)"
        size="xs"
        class="cursor-pointer "
        color="pink"
        :name="$icons.matBookmark"
      >
        <q-tooltip>
          Remove from Saved items
        </q-tooltip>
      </q-icon>
    </div>
    <div
      class="task-menu-option thread-icons row items-center q-px-xs group-hover-item"
      style="right:0px"
    >
      <q-btn
        flat
        :icon="$icons.matLink"
        size="9px"
        rounded
        dense
        @click="
          $copyTextToClipboard(copyTaskLink);
          isTaskCopy = true;
        "
        class="thread-icon q-mr-xs"
      >
        <q-tooltip>
          Copy task Link
        </q-tooltip>
      </q-btn>
      <div
        v-if="cardTask.isbookmarked"
        @click="removeTaskBookmark(cardTask)"
        class="cursor-pointer"
      >
        <q-icon size="xs" color="pink" :name="$icons.matBookmark">
          <q-tooltip>
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <div
        v-if="!cardTask.isbookmarked"
        @click="addTaskBookmark(cardTask)"
        class=" cursor-pointer"
      >
        <q-icon size="xs" color="black" :name="$icons.matBookmarkBorder">
          <q-tooltip>
            Add to Saved items
          </q-tooltip>
        </q-icon>
      </div>
    </div>

    <move-task-dialog
      v-if="moveTask.flag"
      v-model="moveTask.flag"
      :task="taskdata"
      :board="board"
      :closeDialog="closeMoveTaskDialog"
    />
    <duplicate-task-dialog
      v-if="cloneTask.flag"
      v-model="cloneTask.flag"
      :task="taskdata"
      :isFromMyworks="isFromMyworks"
      :userId="userId"
      :closeDialog="closeCloneTaskDialog"
    />
  </tr>
</template>
<script>
const soundFile = require("@/assets/completeTaskSound.wav");
import mixpanel from "@/mixpanel";
import DuplicateTaskDialog from "@/components/Dialogs/DuplicateTaskDialog";
import MoveTaskDialog from "@/components/Dialogs/MoveTaskDialog";
import InviteTaskMemberCell from "@/views/BoardView/BoardListView/InviteTaskMemberCell";
import DueDateCell from "@/views/BoardView/BoardListView/DueDateCell";
import TimerCell from "@/views/BoardView/BoardListView/TimerCell";
import CustomFieldTaskListCell from "@/views/BoardView/BoardListView/CustomFieldTaskListCell";
import UserScope from "@/mixins/UserScope";

import {
  UpdateTaskMutation,
  DetachTaskLabelMutation,
  AddTaskBookmarkMutation,
  RemoveTaskBookmarkMutation,
} from "@/gql";

export default {
  name: "TaskListItem",
  mixins: [UserScope],
  props: [
    "taskdata",
    "labels",
    "board",
    "boardId",
    "workspace",
    "workspaceMembers",
    "place",
    "editTaskDialogHandler",
    "onUpdateTaskMembersHandler",
    "showWorkspace",
    "userId",
    "isFromMyworks",
    "columnFiledList",
    "card",
  ],
  components: {
    DuplicateTaskDialog,
    MoveTaskDialog,
    InviteTaskMemberCell,
    DueDateCell,
    TimerCell,
    CustomFieldTaskListCell,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  mounted() {
    this.sound = new Audio(soundFile);
    this.task = this.taskdata;
  },
  data() {
    return {
      isTruncated: false,
      sound: null,
      task: null,
      cloneTask: {
        flag: false,
      },
      ratingColors: ["yellow-8"],
      isTaskCopy: false,
      moveTask: {
        flag: false,
      },
      isMenuOpen: false,
    };
  },
  methods: {
    openTimelogDialogHendler() {
      this.$eventBus.$emit("openTimelogs", true, this.taskdata.timelogs, true, this.taskdata);
    },
    async changeTaskVisible(flag) {
      const variables = {
        id: this.task.id,
        is_invisible: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) => {
        if (task.id === this.task.id) {
          task.is_invisible = flag;
        }
        return task;
      });
      const task = this.$api.getEntity("task", this.task.id);
      task.is_invisible = flag;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeTaskLock(flag) {
      const variables = {
        id: this.task.id,
        is_lock: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) => {
        if (task.id === this.task.id) {
          task.is_lock = flag;
        }
        return task;
      });
      const task = this.$api.getEntity("task", this.task.id);
      task.is_lock = flag;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    closeMoveTaskDialog() {
      this.moveTask.flag = false;
    },
    async addTaskRating(rating) {
      this.taskdata.rating = rating;
      const variables = {
        id: this.task.id,
        rating: rating,
      };
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        mixpanel.track("Task Update");
      } catch (error) {
        throw new Error(error);
      }
    },
    closeCloneTaskDialog() {
      this.cloneTask.flag = false;
    },
    async changeCompleteStatus() {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: this.task.id,
        completed: !this.task.completed,
      };
      this.taskdata.completed = !this.taskdata.completed;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        mixpanel.track("Task Update");
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeLabel(label, task) {
      const variables = {
        task_id: task.id,
        label_id: label.id,
      };
      await this.$api.mutate({
        mutation: DetachTaskLabelMutation,
        variables,
      });
      mixpanel.track("Task Label Remove");
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.editTaskDialogHandler(obj);
    },

    async addTaskBookmark() {
      try {
        const variables = {
          task_id: this.task.id,
        };

        this.task.isbookmarked = !this.task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Add");
        this.$mixpanelEvent("task-complete", {});
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    async removeTaskBookmark() {
      try {
        const variables = {
          task_id: this.task.id,
        };

        this.task.isbookmarked = !this.task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    dateUpdateHandler(dates) {
      this.updateTask({
        id: this.taskdata.id,
        start_date: dates ? dates.from : null,
        end_date: dates ? dates.to : null,
      });
    },
    async updateTask(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables,
      });
      mixpanel.track("Task Update");
    },
  },
  computed: {
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },

    cardTask() {
      return this.task ? { ...this.taskdata } : {};
    },

    completedSubtasks() {
      if (this.taskdata) {
        return this.taskdata.subtasks.filter((todo) => todo.completed).length;
      } else {
        return 0;
      }
    },
    hasNotifications() {
      const query = this.$api.getQuery("NotificationsQuery");
      if (query.data && this.task) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              (n.action.type === "task-comment-created" &&
                n.action.object &&
                n.action.object.task_id === this.task.id) ||
              (n.action.type === "subtask-member-attached" &&
                n.action.object &&
                n.action.object.task_id === this.task.id) ||
              (n.action.type === "task-member-attached" &&
                n.action.object &&
                n.action.object_id === this.task.id) ||
              (n.action.type === "task-comment-reaction-added" &&
                n.action.object &&
                n.action.object.task_id === this.task.id)
          );
        return notifications;
      }
      return [];
    },
    copyTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.workspace.id}/board?task=${this.taskdata.id}&workspace=${this.workspace.id}&workspaceName=${this.workspace.title}`;
      return taskLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-icon {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5f7ec;
}

.task-list-item:hover {
  border: 1px solid #d1e1f2;
  background-color: #f3f9ff;
}
.task-list-item-active {
  border: 1px solid #d1e1f2;
  background-color: #f3f9ff;
}
</style>
