var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"row justify-center task-column-item",staticStyle:{"min-width":"95px"}},[_c('div',{staticClass:"row justify-center items-center"},[(_vm.taskdata.start_date)?_c('div',{staticClass:"custom-task-tag relative-position",class:[
        'task-due-date cursor-pointer',
        _vm.isEndDateDue,
      ]},[_vm._v(" "+_vm._s(_vm._f("dateMonthDateDefault")(_vm.taskdata.start_date))+" "),_c('q-tooltip',[_vm._v(" Due Date ")])],1):(_vm.taskdata.end_date)?_c('div',{staticClass:"custom-task-tag relative-position",class:[
        'task-due-date cursor-pointer',
        _vm.isStartDateDue,
      ]},[_vm._v(" "+_vm._s(_vm._f("dateMonthDateDefault")(_vm.taskdata.end_date))+" "),_c('q-tooltip',[_vm._v(" Due Date ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }