<template>
  <td
    class="row task-column-item items-center justify-center"
    style="min-width: 140px;width: 100%;max-width:135px"
  >
    <q-circular-progress
      indeterminate
      size="sm"
      :thickness="0.2"
      color="primary"
      v-if="loading"
    />
    <div class="q-px-md" v-else-if="!cardTask.completed">
      <timer
        :startTime="activeTimelog.start_date"
        v-if="activeTimelog && user.id === activeTimelog.owner.id"
        :stopTimer="stopTimerHandler"
        :cardTask="cardTask"
        :deleteTaskTimeLogs="deleteTaskTimeLogs"
        @openTimelogDialog="$emit('openTimelogDialog')"
        :hideEditButton="true"
      />
      <div
        v-if="!activeTimelog && !totalWorkTime"
        class="timer-start-icon row justify-center items-center cursor-pointer card-options"
      >
        <q-icon size="15px" color="primary" :name="$icons.matPlayArrow" @click="showTooltip = false">
        </q-icon>
        <q-tooltip v-if="showTooltip">Track time</q-tooltip>
        <q-menu
          max-height="auto"
          content-class="overflow-auto"
          auto-close
          v-model="trackTimeMenu"
          @before-hide="showTooltip = true"
        >
          <q-list>
            <q-item @click="createTimer()" clickable>
              <q-item-section>Track time</q-item-section>
            </q-item>
            <q-item @click.stop="trackTimeMenu = false; $emit('openTimelogDialog')" clickable>
              <q-item-section>Add time</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
      <div
        class="total-time row items-center flex-no-wrap cursor-pointer relative-position"
        v-if="totalWorkTime && !activeTimelog"
      >
        <q-icon :name="$icons.matPlayCircleFilled" size="20px" />
        <q-menu
          max-height="auto"
          content-class="overflow-auto"
          auto-close
          v-model="trackTimeMenu"
        >
          <q-list>
            <q-item @click="createTimer()" clickable>
              <q-item-section>Track time</q-item-section>
            </q-item>
            <q-item @click="$emit('openTimelogDialog')" clickable>
              <q-item-section>Add time</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <span
          class="q-ml-sm time-text row items-center"
          style="margin-top:1px;"
          v-if="totalWorkTime"
          >{{ totalWorkTime }}
        </span>

        <div class="time-log-edit-delete row items-center">
          <div
            class="cursor-pointer edit-icon"
            @click.stop="$emit('openTimelogDialog')"
            style="display: flex"
          >
            <q-icon size="16px" class="q-ml-xs" :name="$icons.matEdit" />
            <q-tooltip>Edit Time Report</q-tooltip>
          </div>
          <div
            class="delete-icon row items-center"
            clickable
            @click.stop="deleteTaskTimeLogs"
          >
            <q-icon size="16px" class="q-mr-xs" :name="$icons.matDelete" />
            <q-tooltip>Delete Time Report</q-tooltip>
          </div>
        </div>
      </div>
    </div>

    <start-new-timer-dialog
      v-if="startNewTimer.flag"
      v-model="startNewTimer.flag"
      :activeTaskId="startNewTimer.activeTaskId"
      :createTimer="createTimer"
      :currentCompany="currentCompany"
      @closeDialog="
        startNewTimer.flag = false;
        startNewTimer.activeTaskId = null;
      "
    />
    <stop-timer-confirmation-dialog
      v-if="stopTimerConfirmation.flag"
      v-model="stopTimerConfirmation.flag"
      :timelogId="stopTimerConfirmation.id"
      :stopTimer="stopTimer"
      @closeDialog="
        stopTimerConfirmation.flag = false;
        stopTimerConfirmation.id = null;
      "
    />
  </td>
</template>
<script>
import { mapGetters } from "vuex";
import { fromUnixTime, getUnixTime } from "date-fns";
import get from "lodash/get";
import Timer from "@/components/Timer";
import StartNewTimerDialog from "@/components/Dialogs/StartNewTimerDialog";
import StopTimerConfirmationDialog from "@/components/Dialogs/StopTimerConfirmationDialog";
import {
  UpdateTaskTimeLogMutation,
  UpdateCompanySettingsMutation,
  DeleteTaskTimeLogsMutation,
  CreateTaskTimeLogMutation,
} from "@/gql";

export default {
  props: ["cardTask", "taskdata", "task", "workspace", "user", "board"],
  components: {
    Timer,
    StartNewTimerDialog,
    StopTimerConfirmationDialog,
  },
  data() {
    return {
      loading: false,
      startNewTimer: {
        flag: false,
        activeTaskId: null,
      },
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
      trackTimeMenu: false,
      showTooltip:true,
    };
  },
  mounted() {
    if (this.taskdata.activetimelog) {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = this.task.id;
      companyEntity.start_time_log_id = this.taskdata.activetimelog.id;
    }
  },
  methods: {
    async createTimer() {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      if (!companyEntity.start_task_id && !companyEntity.start_time_log_id) {
        this.loading = true;
        const variables = {
          start_date: getUnixTime(new Date()),
          end_date: getUnixTime(new Date()),
          task_id: this.cardTask.id,
          workspace_id: this.workspace.id,
          is_start: true,
        };
        let response = await this.$api.mutate({
          mutation: CreateTaskTimeLogMutation,
          variables,
        });

        const taskEntity = this.$api.getEntity("task", this.cardTask.id);
        taskEntity.timelogs.push(response.data.createTaskTimeLog);
        this.workspace.timelogs.push(response.data.createTaskTimeLog);
        companyEntity.start_task_id = this.task.id;
        companyEntity.start_time_log_id = response.data.createTaskTimeLog.id;

        this.taskdata.activetimelog = response.data.createTaskTimeLog;
        this.loading = false;
        const companyVariables = {
          name: companyEntity.name,
          start_task_id: this.cardTask.id,
          start_time_log_id: response.data.createTaskTimeLog.id,
        };
        await this.$api.mutate({
          mutation: UpdateCompanySettingsMutation,
          variables: companyVariables,
        });
        this.$mixpanelEvent("track-time", companyVariables);
      } else {
        this.startNewTimer.flag = true;
        this.startNewTimer.activeTaskId = companyEntity.start_task_id;
      }
    },
    stopTimerHandler(data) {
      this.stopTimerConfirmation.flag = true;
      this.stopTimerConfirmation.id = data.id;
    },
    async stopTimer(timelogId) {
      const variables = {
        end_date: getUnixTime(new Date()),
        id: timelogId,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });

      this.taskdata.activetimelog = null;
      this.task.activetimelog = null;
      const taskEntity = this.$api.getEntity("task", this.task.id);

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == timelogId
      );
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.workspace.id,
        task_id: this.taskdata.id,
        user_id: this.user.id,
      };
      const taskEntity = this.$api.getEntity("task", this.taskdata.id);

      taskEntity.timelogs = [];
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });
    },

    timeCaclulate(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      return Math.floor((dateFuture - dateNow) / 1000);
    },
    convertIntoTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      //seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      return days * 24 + hours + "h " + minutes + "m";
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    totalWorkTime() {
      let taskTotalTime;
      if (this.cardTask.timelogs?.length) {
        let timeInSecond = 0;
        this.cardTask.timelogs.forEach((timelog) => {
          if(this.user.id === timelog.owner.id){
            timeInSecond += this.timeCaclulate(
              timelog.start_date,
              timelog.end_date
            );
          }
        });
        if(timeInSecond > 0) {
          taskTotalTime = this.convertIntoTime(timeInSecond);
        }
        return taskTotalTime;
      }
      return null;
    },
    activeTimelog() {
      return this.cardTask.activetimelog;
    },
  },
};
</script>
