<template>
  <td style="min-width:130px;" class="row justify-center task-column-item">
    <div class="row items-center justify-center">
      <div v-if="first2TaskMembers">
        <q-avatar
          color="grey-4"
          text-color="grey-10"
          v-for="(member, index) in first2TaskMembers"
          :class="[
            index < first2TaskMembers.length - 1 || taskMembers.length > 2
              ? 'task-member-position'
              : '',
            'cursor-pointer',
          ]"
          :key="`ontask-userseq-${member.id}`"
          size="25px"
        >
          <img :src="member.pic" :alt="member.first" v-if="member.pic" />
          <!-- <span v-else>
            {{ member.first | firstChar }}{{ member.last | firstChar }}
          </span> -->
          <avatar v-else :size="25" :customStyle="{'font-size':'12px'}" :username="member.first+' '+member.last"></avatar>
          <q-tooltip>
            {{ member.first | capitalize }}
            {{ member.last | capitalize }}
          </q-tooltip>
        </q-avatar>
        <q-avatar
          class="avatar-circle-border-1px member-count"
          size="25px"
          v-if="taskMembers.length > 2"
        >
          <span
            class="text-avatar-style text-black"
            style="font-size:10px"
            @click.stop="showMenu = !showMenu"
            >+{{ taskMembers.length - first2TaskMembers.length }}</span
          >
        </q-avatar>
        <q-menu
          content-class="overflow-auto assign-member-menu"
          auto-close
          v-model="showMenu"
        >
          <div class="sticky-header">
            <div
              class="close-btn cursor-pointer"
              style="right:10px"
              @click="showMenu = false"
            >
              <q-icon :name="$icons.matClose" class="close-icon" />
            </div>
            <div class="assign-to q-pt-sm q-px-sm">
              Assigned to
            </div>
          </div>
          <q-list class="q-py-sm q-px-sm member-list">
            <q-item
              class="q-px-none q-py-xs member-item items-center"
              v-for="member in taskMembers"
              v-bind:key="`member-list-${member.id}`"
            >
              <q-item-section
                @click="showUserInfo(member)"
                class="member-avatar cursor-pointer"
                avatar
              >
                <q-avatar v-if="member.pic" size="32px">
                  <img :src="member.pic" :alt="member.first" />
                </q-avatar>
                <!-- <q-avatar
                  size="32px"
                  color="grey-4"
                  text-color="grey-10"
                  v-else
                >
                  {{ member.first | firstChar }}{{ member.last | firstChar }}
                </q-avatar> -->
                <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
              </q-item-section>
              <q-item-section class="member-name" @click="showUserInfo(member)">
                {{ member.first | capitalize }}
              </q-item-section>
              <q-item-section avatar>
                <q-btn
                  flat
                  round
                  dense
                  color="grey-7"
                  :icon="$icons.matClose"
                  size="sm"
                  @click="confirmBoxHandler(true, member.id)"
                  v-if="
                    isVisible([1, 2]) ||
                      isWorkspaceOwner ||
                      taskdata.owner.id === user.id
                  "
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="profileDialog.user"
      :currentWorkspaceId="workspace.id"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <confirm-dialog
      v-if="confirmRemoveMember.flag"
      v-model="confirmRemoveMember.flag"
      title="Remove Member?"
      question="Are you sure you want to remove this member ?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteTaskMember"
      :loading="false"
    />
  </td>
</template>
<script>
import mixpanel from "@/mixpanel";
import ProfileDialog from "@/components/ProfileDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import UserScope from "@/mixins/UserScope";
import { DeleteTaskMemberMutation } from "@/gql";
import Avatar from 'vue-avatar';

export default {
  props: ["taskdata", "isWorkspaceOwner", "task", "workspace"],
  mixins: [UserScope],
  data() {
    return {
      showMenu: false,
      profileDialog: {
        show: false,
        user: null,
      },
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
    };
  },
  components: {
    ProfileDialog,
    ConfirmDialog,
    Avatar,
  },
  methods: {
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    async deleteTaskMember() {
      const variables = {
        task_id: this.task.id,
        user_id: this.confirmRemoveMember.id,
      };

      this.confirmRemoveMember.flag = false;

      const response = await this.$api.mutate({
        mutation: DeleteTaskMemberMutation,
        variables,
      });

      const myWorksQuery = this.$api.getQuery(
        `MyWorkQuery:${parseInt(this.user.id)}`
      );
      
      if(this.confirmRemoveMember.id == this.user.id) {
        myWorksQuery.data.myWorks = myWorksQuery.data.myWorks.filter(
          (t) => t.id !== this.task.id
        );
      } else {
        for (let index = 0; index < myWorksQuery.data.myWorks.length; index++) {
          if(myWorksQuery.data.myWorks[index].id == this.task.id){
            myWorksQuery.data.myWorks[index].members = myWorksQuery.data.myWorks[index].members.filter(
              (member) => member.id !== this.confirmRemoveMember.id
            );  
          }
        }
      }

      this.task.members = this.task.members.filter(
        (member) => member.id !== this.confirmRemoveMember.id
      );

      mixpanel.track("Task Member Remove");
      if (response) {
        this.onUpdateTaskMembersHandler({
          id: this.task.id,
        });
      }
    },
  },
  computed: {
    taskMembers() {
      return this.taskdata.members;
    },
    first2TaskMembers() {
      return this.taskMembers !== undefined && this.taskMembers.length > 2
        ? this.taskMembers.slice(0, 2)
        : this.taskMembers;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
  },
};
</script>
