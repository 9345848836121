<template>
  <div class="relative-position">
    <div
      v-if="!boardCards.length"
      class="column items-center justify-center"
      style="height: calc(100vh - 44px); background: #f9fafc"
    >
      <q-circular-progress
        indeterminate
        size="75px"
        :thickness="0.1"
        color="primary"
      />
    </div>
    <div
      @click.stop="$emit('closeNotificaiton')"
      class="column relative-position"
      style="
        background: #f9fafc;
        flex-wrap: nowrap;
        height: calc(100vh - 85px);
        overflow: auto;
      "
      v-else
    >
      <div
        v-if="user.onboarding.is_mywork"
        class="empty-timeline"
        style="margin-bottom: 0"
      >
        <div class="demo" style="margin-bottom: 16px">
          <div class="tune-icon">
            <q-icon
              :name="$icons.matCheck"
              size="15px"
              style="
                color: #15d89a;
                background-color: white;
                border-radius: 5px;
              "
            />
          </div>
          <div class="video">
            <div class="thumbnail" @click="videoPlayer">
              <q-icon :name="$icons.matPlayArrow" color="primary" size="xl" />
            </div>
          </div>
          <div class="text-contents">
            <span class="text-bold" style="margin-left: 31px"
              >All tasks assigned to you across workspaces</span
            >
            <p>
              Here you can see all the tasks that are assigned to you from the
              workspaces you are part of.
            </p>
            <div
              style="width: 50px; margin-left: 31px; margin-top: 10px"
              class="d-flex"
            >
              <!-- <q-btn
                dense
                no-caps
                class="primary-button"
                color="#15d89a"
                size="md"
                label="Learn more"
                @click="$router.push({ name: 'LearnView' })"
              /> -->
              <q-btn
                dense
                no-caps
                style="
                  margin-left: 10px;
                  height: 32px;
                  border: solid 1px #677484;
                "
                size="md"
                label="Dismiss"
                @click="closeWalkthroughBanner"
              />
            </div>
          </div>
          <div class="close-icon" @click="closeWalkthroughBanner">
            <q-icon :name="$icons.matClose" size="22px" />
          </div>
        </div>
        <video-dialog
          v-if="showVideoModel"
          v-model="showVideoModel"
          :resetVideoDialog="resetVideoDialog"
          :videoMediaData="videoMediaData"
          :displayPlayer="displayPlayer"
        ></video-dialog>
      </div>
      <div>
        <div class="task-list-view q-pl-xs overflow-auto q-mb-md">
          <!-- My Tasks Stats -->
          <div class="row q-pl-md">
            <div
              class="row col-12 col-sm-4 col-md-2 col-lg-2 items-center justify-space-between statistic-box q-mr-md q-my-md"
            >
              <div class="row items-center">
                <div class="my-work" style="border-radius: 50%">
                  <q-icon
                    :name="$icons.matCheck"
                    class="my-work-icon"
                    size="24px"
                    style="color: #15d89a"
                  />
                </div>
                <div class="my-title">Tasks</div>
                <q-tooltip> Tasks completed </q-tooltip>
              </div>
              <div class="digit-title">
                {{ taskComplated }}
              </div>
            </div>
            <div
              class="row col-12 col-sm-4 col-md-2 col-lg-2 items-center justify-space-between statistic-box q-mr-md q-my-md"
            >
              <div class="row items-center">
                <div
                  class="my-work"
                  style="border-radius: 50%; background: #dfefff"
                >
                  <q-icon
                    :name="$icons.matSchedule"
                    class="my-work-icon"
                    size="16px"
                    style="color: #007aff"
                  />
                </div>
                <div class="my-title">Hours</div>
                <q-tooltip>Hours worked</q-tooltip>
              </div>
              <div class="digit-title">
                {{ getTaskTotalHours }}
              </div>
            </div>
            <div
              class="row col-12 col-sm-4 col-md-2 col-lg-2 items-center justify-space-between statistic-box q-mr-md q-my-md"
            >
              <div class="row items-center">
                <div
                  class="my-work"
                  style="border-radius: 50%; background: #e8e5f7"
                >
                  <q-icon
                    :name="$icons.fasComment"
                    class="my-work-icon"
                    size="16px"
                    style="color: #6417ed"
                  />
                </div>
                <div class="my-title">Messages</div>
                <q-tooltip>Messages sent</q-tooltip>
              </div>
              <div class="digit-title" style="">
                {{ postCount }}
              </div>
            </div>
            <div
              class="row col-12 col-sm-4 col-md-2 col-lg-2 items-center justify-space-between statistic-box q-mr-md q-my-md"
            >
              <div class="row items-center">
                <div
                  class="my-work"
                  style="border-radius: 50%; background: #ffe5ec"
                >
                  <q-icon
                    :name="$icons.fasDotCircle"
                    class="my-work-icon"
                    size="16px"
                    style="color: #e50c45"
                  />
                </div>
                <div class="my-title">Comments</div>
                <q-tooltip>Comments posted</q-tooltip>
              </div>
              <div class="digit-title">
                {{ getTaskComment }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="myWorksLoading || myCardsLoading"
          class="column items-center justify-center"
          style="height: calc(100vh - 165px)"
        >
          <q-circular-progress
            indeterminate
            size="75px"
            :thickness="0.1"
            color="primary"
          />
        </div>
        <div
          v-else
          class="task-list-view q-pl-xs q-py-md overflow-auto my-task-list-container"
        >
          <div>
            <table class="q-pr-md" style="table-layout: fixed">
              <thead class="table-title flex-1 row flex-no-wrap">
                <tr
                  class="flex-1 row flex-no-wrap q-pr-lg"
                  style="min-width: 1005px"
                >
                  <th
                    style="min-width: 350px; max-width: 350px; width: 100%"
                    class="flex-1"
                  ></th>
                  <th style="font-weight: 500; min-width: 130px">
                    Assigned to
                  </th>
                  <th style="font-weight: 500; min-width: 95px">Due date</th>
                  <th style="font-weight: 500; min-width: 140px">
                    Tracked time
                  </th>
                  <th style="font-weight: 500; min-width: 100px">Subtasks</th>
                  <th style="font-weight: 500; min-width: 150px">Tags</th>
                  <th style="font-weight: 500; min-width: 200px">Workspace</th>
                </tr>
              </thead>
              <tbody>
                <div
                  v-sortable="{
                    filter: '.static',
                    onEnd: onDragEnd,
                  }"
                >
                  <template v-for="card in boardCards">
                    <my-card-list-item
                      :key="card.id"
                      :data-id="card.id"
                      :card="card"
                      :labels="taskLabels"
                      :tasks="card.tasks"
                      :userId="userId"
                      :editTaskDialogHandler="openTaskDailog"
                      :onUpdateTaskMembersHandler="
                        reactivityOnUpdateTaskMembers
                      "
                      :addNewColumnFromColumn="(card) => addCard(card)"
                      @deleteTask="deleteTaskConfirmBoxHandler"
                      @updateCard="updateCardHandler"
                      @deletCard="deleteCardHandler"
                    />
                  </template>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <q-dialog
        transition-hide="none"
        transition-show="none"
        v-model="taskDialog"
        persistent
        @before-hide="closeDialog"
        @keydown.esc="resetTaskDialog"
      >
        <task-dialog
          v-if="editTaskdata"
          :tasks="editTaskdata"
          :reactions="reactions"
          :boardId="workspace.boards[0].id"
          :workspaceid="workspace.id"
          :workspace="workspace"
          :isWorkspaceOwner="isWorkspaceOwner"
          :currentUser="user"
          :authToken="currentCompany.accessToken"
          :workspaceMembers="workspaceMembers"
          :taskLabels="taskLabels"
          :openSubtask="openSubtask"
          :openTaskComment="openTaskComment"
          :currentCompany="currentCompany"
          :attachTaskMediaHandler="attachTaskMedia"
          :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
          :deleteTaskCommentHandler="deleteTaskComment"
          :detachTaskAttachmentHandler="detachTaskAttachment"
          @reactOnPost="reactOnPost"
          @updateMediaCache="updateMediaCache"
          @createSubTask="createSubTask"
          @close="resetTaskDialog"
          @success="taskDialogSuccessHandler"
          @clearSubtasks="clearSubtasks"
          @createComment="createComment"
          @clickonmoreoption="clickonmoreoption"
          @deleteCommentPoint="deleteCommentPoint"
          @closeMediaModel="closeMediaModel"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @updatepositions="updatepositions"
          @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
          @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
          :openSubtaskDialog="openSubtaskDialog"
        ></task-dialog>
      </q-dialog>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        persistent
        v-model="subTaskDialog"
        @before-hide="closeSubTaskDialog"
      >
        <sub-task-dialog
          v-if="currentSubTask"
          :taskLabels="taskLabels"
          :tasks="currentTask"
          :subTask="currentSubTask"
          :workspace="workspace"
          :workspaceid="workspace.id"
          :workspaceMembers="workspaceMembers"
          :currentCompany="currentCompany"
          :authToken="currentCompany.accessToken"
          :reactions="reactions"
          @createComment="createComment"
          :openTaskComment="openTaskComment"
          :deleteTaskCommentHandler="deleteTaskComment"
          @deleteCommentPoint="deleteCommentPoint"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @clearSubtasks="clearChildSubtasks"
          :backToTask="backToTask"
          @createSubTask="createSubTask"
        ></sub-task-dialog>
      </q-dialog>
      <div v-if="confirmBox.taskRemove.id && confirmBox.taskRemove.flag">
        <confirm-dialog
          v-model="confirmBox.taskRemove.flag"
          title="Delete task ?"
          question="Deleting this task will also delete all the sub-tasks and comments in it."
          cancleText="Cancel"
          successText="Delete"
          :cancleMethod="resetTaskRemoveConfirmbox"
          :successMethod="deleteMyTask"
          :loading="confirmBox.taskRemove.loader"
        />
      </div>
      <participant-video-call-dialog
        v-model="videoCallData.isDialogShow"
        v-if="videoCallData.isDialogShow"
        :roomName="videoCallData.roomName"
        :jwtToken="videoCallData.jwtToken"
        @closeDialog="closeVideoCallDialog"
      />
      <new-task-dialog
        v-if="addTaskSection"
        v-model="addTaskSection"
        :closeDialog="closeNewTaskDialog"
        :taskLabels="taskLabels"
        :userId="user.id"
        :showWorkspaceSection="true"
        sequencePosition="top"
      />
    </div>
    <button class="fab-btn" style="margin-bottom: 16px">
      <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
      <q-menu
        content-style="width:150px;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
        v-model="fabMenu"
        auto-close
        :offset="[0, 0]"
      >
        <q-list class="q-py-sm">
          <q-item
            @click="addCard(null)"
            :class="['items-center q-px-lg']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.matViewList"
              size="18px"
              style="margin-right: 20px"
            />
            New list
          </q-item>
          <q-item
            @click="addTaskSection = true"
            :class="['items-center q-px-lg']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.fasCheck"
              size="18px"
              style="margin-right: 20px"
            />
            New task
          </q-item>
        </q-list>
      </q-menu>
    </button>
  </div>
</template>
<script>
import fromUnixTime from "date-fns/fromUnixTime";
import RandomPosition from "random-position";
// import mixpanel from "@/mixpanel";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import findLastIndex from "lodash/findLastIndex";
import isThisWeek from "date-fns/isThisWeek";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MyCardListItem from "@/views/BoardView/MyCardListItem";
import TaskDialog from "@/views/BoardView/TaskDialog";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ParticipantVideoCallDialog from "@/components/Dialogs/ParticipantVideoCallDialog";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";

let meetWindow;

import {
  PostCountQuery,
  UpdateUserCardMutation,
  PrivateTasksQuery,
  DeleteTaskMutation,
  DeleteUserCardMutation,
  MyCardsQuery,
  MyWorkQuery,
  TaskQuery,
  SubtaskQuery,
  GenerateJwtTokenForUser,
  UpdateUserOnboardingMutation,
  CreateUserCardMutation,
} from "@/gql";
export default {
  name: "MemberView",
  mixins: [BoardsMethodMixin, MediaMethodMixin],
  props: ["myworkSearch", "myWorkDateFilter", "toggleDrawerRight", "drawerRight"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    postCount: {
      query: PostCountQuery,
      variables() {
        return {
          user_id: parseInt(this.$route.params.userId),
        };
      },
      cacheKey() {
        return `PostCountQuery:${parseInt(this.$route.params.userId)}`;
      },
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: [],
    },
    myCards: {
      query: MyCardsQuery,
      defaultValue: [],
      variables() {
        return {
          user_id: parseInt(this.$route.params.userId),
        };
      },
      cacheKey() {
        return `MyCardsQuery:${parseInt(this.$route.params.userId)}`;
      },
    },
    myWorks: {
      query: MyWorkQuery,
      defaultValue: [],
      variables() {
        return {
          user_id: parseInt(this.$route.params.userId),
        };
      },
      cacheKey() {
        return `MyWorkQuery:${parseInt(this.$route.params.userId)}`;
      },
    },
    // myCards: {
    //   query: MyCardQuery,
    //   cacheKey: "MyCardQuery",
    //   defaultValue: [],
    // },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
  },
  components: {
    MyCardListItem,
    TaskDialog,
    SubTaskDialog,
    ConfirmDialog,
    ParticipantVideoCallDialog,
    NewTaskDialog,
    VideoDialog,
  },
  data() {
    return {
      subTaskDialog: false,
      currentTask: null,
      currentSubTask: null,
      videoCallData: {
        isDialogShow: false,
        roomName: null,
        jwtToken: null,
        window: null,
      },
      workspace: null,
      taskDialog: false,
      task: null,
      board: null,
      workspaceMembers: null,
      confirmBox: {
        taskRemove: {
          flag: false,
          loader: false,
          id: null,
        },
      },
      fabMenu: false,
      addTaskSection: false,
      showVideoModel: false,
      videoMediaData: null,
      displayPlayer: false,
      lastTaskId: null,
    };
  },
  created() {
    document.addEventListener("click", this.docClickEventHandler, false);
  },
  methods: {
    addCard(card) {
      if (card) {
        let newIndex;
        const currentIndex = this.boardCards.findIndex((a) => a.id === card.id);
        if (currentIndex === 0) {
          newIndex = this.cardSequenceGenerator(2, 0);
        } else {
          newIndex = this.cardSequenceGenerator(currentIndex - 1, currentIndex);
        }
        const cardData = {
          title: "New List",
          board_id: 1,
          workspace_id: 1,
          sequence: newIndex,
          user_id: this.userId,
        };
        this.createCard(cardData);
      } else {
        const sequence =
          this.boardCards && this.boardCards.length > 0
            ? RandomPosition.between(
                last(this.boardCards).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                last(this.boardCards).sequence,
                RandomPosition.last()
              );
        const card = {
          title: "New List",
          board_id: 1,
          workspace_id: 1,
          sequence,
          user_id: this.userId,
        };
        this.createCard(card);
        this.card_title = null;
      }
    },
    async createCard(card) {
      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateUserCardMutation,
        variables: card,
      });
      const fakeCard = {
        __typename: "userCard",
        id: response.data.createUserCard.id,
        title: response.data.createUserCard.title,
        user_id: this.userId ? this.userId : this.user.id,
        sequence: card.sequence,
        color: null,
      };
      this.myCards.unshift({ ...fakeCard });
      // mixpanel.track("Card Create");
      setTimeout(() => {
        var selectedCard = document.getElementById(
          response.data.createUserCard.id
        );
        selectedCard.scrollIntoView();
      }, 500);
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
    },
    async openTask(taskId) {
      const task = this.$api.getEntity("task", taskId);
      let taskEntity = null;
      if (task) {
        taskEntity = task;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        taskEntity = taskQueryData.data.task;
      }

      if (taskEntity) {
        let isOpen = null;
        let task = taskEntity;
        let taskDetails = {
          task,
          isOpen,
        };
        this.editTask(taskDetails);
        this.taskDialog = true;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async openSubtaskDialog(
      taskId,
      subtaskId,
      taskData = null,
      subtask = null
    ) {
      if (taskData) {
        this.currentTask = taskData;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if (subtask) {
        subtaskEntity = subtask;
      } else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });

        subtaskEntity = subtaskQueryData.data.subtask;
      }

      if (subtaskEntity) {
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
        this.closeDialog();
        this.resetTaskDialog();
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async backToTask(taskId) {
      await this.openTask(taskId);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    closeVideoCallDialog(event) {
      if (
        !meetWindow ||
        typeof event.data.isCloseDialog == "undefined"
      ) {
        return false;
      }
      event.preventDefault();
      meetWindow.close();
      return true;
    },
    hasClass(elem, className) {
      return elem.className.split(" ").indexOf(className) > -1;
    },
    async docClickEventHandler(event) {
      const element = event.target;
      if (this.hasClass(element, "mention")) {
        event.preventDefault();
        const member = element.dataset;
        if (member && has(member, "id")) {
          const memberObject = this.workspaceMembers.find(
            (u) => u.username === member.id
          );
          if (memberObject) {
            this.showUserInfo(memberObject);
          }
        }
      }

      if (this.hasClass(element, "taskmention")) {
        if (this.task) {
          this.lastTaskId =
            this.lastTaskId == this.task.id ? null : this.task.id;
        } else {
          this.lastTaskId = null;
        }
        // this.closeDialog();
        this.resetTaskDialog();
        event.preventDefault();
        const taskUrl = element.dataset;
        if (taskUrl && has(taskUrl, "id")) {
          let taskData = taskUrl.id.split("-");
          this.workspace = this.$api.getEntity(
            "workspace",
            taskData[1].replace(/\D/g, "")
          );
          // console.log("this.workspace", this.workspace, workspace);
          //if (workspace.id != this.workspace.id) {
          this.board = this.$api.getEntity(
            "board",
            this.workspace.boards[0].id
          );
          //}
          // const taskEntity = this.$api.getEntity("task", taskData[0]);

          if (taskData.length > 2) {
            this.openSubtaskDialog(taskData[0], taskData[2]);
          } else {
            let taskId = taskData[0].replace(/\D/g, "");
            this.openTask(taskId);
          }
        }
      }

      if (this.hasClass(element, "open-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          let userId = videoData.user;

          if (userId && userId == this.user.id) {
            let generateToken = await this.$api.mutate({
              mutation: GenerateJwtTokenForUser,
            });

            if (generateToken.data) {
              videoUrl += "?jwt=" + generateToken.data.generateJwtTokenForUser;
            }
          }

          meetWindow = window.open(
            videoUrl,
            "heycollabVideo",
            "width=600,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
          );

          window.addEventListener("message", this.closeVideoCallDialog, false);
        }
      }

      if (this.hasClass(element, "copy-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          this.$copyTextToClipboard(videoUrl);
        }
      }
    },
    onDragEnd(event) {
      const { item, oldIndex, newIndex } = event;
      if (Number(item.dataset.id)) {
        const myCardQuery = this.$api.getQuery(
          `MyCardsQuery:${parseInt(this.userId)}`
        );
        const card = myCardQuery.data.myCards.filter(
          (mycard) => mycard.id === Number(item.dataset.id)
        );
        const tmp = [...this.boardCards];

        // move item from oldIndex to newIndex
        tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);

        card[0].sequence = RandomPosition.between(
          tmp[newIndex - 1]
            ? tmp[newIndex - 1].sequence
            : RandomPosition.first(),
          tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
        );

        this.$api.mutate({
          mutation: UpdateUserCardMutation,
          skipUpdates: true,
          variables: {
            id: card[0].id,
            sequence: card[0].sequence,
          },
        });
        // mixpanel.track("Card Move");
      }
    },
    async openTaskDailog(data) {
      this.workspace = this.$api.getEntity(
        "workspace",
        data.task.myworks[0].workspace_id
      );
      if (this.workspace) {
        this.board = this.$api.getEntity("board", this.workspace.boards[0].id);
        await this.$api.query({
          query: PrivateTasksQuery,
          variables: {
            board_id: this.workspace.boards[0].id,
          },
          cacheKey() {
            return `PrivateTasksQuery:${this.workspace.boards[0].id}`;
          },
        });
      }
      this.workspaceMembers = this.workspace.users;
      this.editTask(data);
    },
    async deleteMyTask() {
      this.confirmBox.taskRemove.loader = true;
      if (this.confirmBox.taskRemove.id !== null) {
        const id = this.confirmBox.taskRemove.id;
        const variables = {
          id,
        };
        await this.$api.mutate({
          mutation: DeleteTaskMutation,
          variables,
        });
        const myWorksQuery = this.$api.getQuery(
          `MyWorkQuery:${parseInt(this.userId)}`
        );
        myWorksQuery.data.myWorks = myWorksQuery.data.myWorks.filter(
          (t) => t.id !== this.confirmBox.taskRemove.id
        );
        this.resetTaskRemoveConfirmbox();
        // mixpanel.track("Task Delete");
      } else {
        this.resetTaskRemoveConfirmbox();
      }
    },
    async updateCardHandler(card) {
      const hasId = this.myCards.find((c) => {
        if (c.id === card.id) {
          c.title = card.title;
          return c;
        }
      });
      const variables = card;
      if (hasId) {
        this.$api.updateEntity("card", card.id, card);
        await this.$api.mutate({
          mutation: UpdateUserCardMutation,
          variables,
        });
        // mixpanel.track("Card Update");
      }
    },
    async deleteCardHandler(card) {
      const variables = {
        id: card.id,
      };
      const myCardQuery = this.$api.getQuery(
        `MyCardsQuery:${parseInt(this.userId)}`
      );

      myCardQuery.data.myCards = myCardQuery.data.myCards.filter(
        (c) => c.id !== card.id
      );

      await this.$api.mutate({
        mutation: DeleteUserCardMutation,
        variables,
      });
      // mixpanel.track("Card Delete");
    },
    cardSequenceGenerator(index, futureIndex) {
      let newIndex;
      if (futureIndex === 0) {
        newIndex = RandomPosition.between(
          RandomPosition.first(),
          this.boardCards[0].sequence
        );
        return newIndex;
      } else if (futureIndex === findLastIndex(this.boardCards)) {
        newIndex = RandomPosition.between(
          last(this.boardCards).sequence,
          RandomPosition.last()
        );
        return newIndex;
      } else if (
        futureIndex < index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.boardCards)
      ) {
        let ref = futureIndex - 1;
        newIndex = RandomPosition.between(
          this.boardCards[ref].sequence,
          this.boardCards[futureIndex].sequence
        );
        return newIndex;
      } else if (
        futureIndex > index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.boardCards)
      ) {
        let ref = futureIndex + 1;
        newIndex = RandomPosition.between(
          this.boardCards[futureIndex].sequence,
          this.boardCards[ref].sequence
        );
        return newIndex;
      }
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
      if (this.lastTaskId) {
        this.openTask(this.lastTaskId);
        this.lastTaskId = null;
      }
    },
    videoPlayer() {
      this.videoMediaData = {
        file: "https://player.vimeo.com/video/585637023",
        isVimeo: true,
      };
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async closeWalkthroughBanner() {
      this.user.onboarding.is_mywork = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_mywork",
          flag_value: false,
        },
      });
    },
  },
  computed: {
    taskComplated() {
      let complatedTask = 0;
      if (this.myWorks) {
        complatedTask =
          this.myWorks &&
          this.myWorks.filter((o) => o.completed === true).length;
      }
      return complatedTask + "/" + this.myWorks.length;
    },
    getTaskTotalHours() {
      let seconds = 0;
      this.myWorks &&
        this.myWorks.forEach((element) => {
          element.timelogs &&
            element.timelogs.forEach((timelog) => {
              let dateFuture = fromUnixTime(timelog.end_date);
              let dateNow = fromUnixTime(timelog.start_date);

              let tmp = Math.floor((dateFuture - dateNow) / 1000);
              seconds += tmp;
            });
        });
      var sec_num = parseInt(seconds, 10);
      var hours = Math.floor(sec_num / 3600);
      // var minutes = Math.floor(sec_num / 60) % 60;
      // var second = seconds % 60;
      return hours + "h";
    },
    getTaskComment() {
      let comment = 0;
      this.myWorks &&
        this.myWorks.forEach((element) => {
          if (element.comments) {
            comment += element.comments.filter(
              (o) => o.user_id === this.userId
            ).length;
          }
        });
      return comment;
    },
    activeTasks() {
      return this.myWorks.filter((o) => o.completed !== true).filter((c) => ((c.owner.id === this.user.id && c.is_invisible) || !c.is_invisible));
    },
    boardCards: {
      get() {
        let cards = [];
        let cardData = groupBy(
          orderBy(this.filteredMywork, (mywork) => {
            return mywork.myworks[0].user_sequence;
          }),
          (mywork) => {
            return mywork.myworks[0].user_card_id;
          }
        );

        if (cardData) {
          let data = {
            id: 0,
            title: `Tasks assigned to ${
              this.user.id === this.userId
                ? "me"
                : this.$api.getEntity("user", this.userId).first +
                  " " +
                  this.$api.getEntity("user", this.userId).last
            }`,
            tasks: cardData["null"],
          };
          cards = [...cards, data];

          this.myCards.forEach((element) => {
            element.tasks = cardData[element.id];
            cards = [...cards, element];
          });
        }

        return orderBy(cards, (card) => {
          if (!card.id) {
            return true;
          }
          return card.sequence;
        });
      },
    },
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    filteredMywork() {
      let task;
      if (this.myWorkDateFilter === "This week") {
        task = this.activeTasks.filter((t) => {
          if (
            t.end_date &&
            isThisWeek(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
      } else if (this.myWorkDateFilter === "Today") {
        task = this.activeTasks.filter((t) => {
          if (
            t.end_date &&
            isToday(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
      } else {
        task = this.activeTasks;
      }
      if (this.myWorkTaskSerachText) {
        task = task.filter(
          (o) =>
            (o.title &&
              o.title
                .toLowerCase()
                .includes(this.myWorkTaskSerachText.toLowerCase())) ||
            (o.details &&
              o.details
                .toLowerCase()
                .includes(this.myWorkTaskSerachText.toLowerCase())) ||
            (o.id &&
              String(o.id).includes(this.myWorkTaskSerachText.toLowerCase()))
        );
      }
      return task;
    },
    myWorkTaskSerachText() {
      return this.myworkSearch && this.myworkSearch.taskSearchText
        ? this.myworkSearch.taskSearchText
        : "";
    },
    userId() {
      if (this.$route.params.userId) {
        return Number(this.$route.params.userId);
      } else {
        return this.user.id;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.docClickEventHandler, false);
  },
};
</script>
<style lang="scss" scoped>
.fab-btn {
  position: absolute;
  bottom: 35px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #007aff;
  border-radius: 12px;
  /* box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f; */
  border: 3px solid #dbecff;
  z-index: 999;
}

.statistic-box {
  border-radius: 12px;
  box-shadow: 0 2px 15px 10px rgba(229, 237, 240, 0.33);
  background-color: #ffffff;
  padding: 20px;
  flex-grow: 1;
  .my-title {
    font-size: 18px;
    font-weight: 600;
    color: #34335b;
  }
  .digit-title {
    font-size: 20px;
    color: #34335b;
    font-weight: bold;
  }
}
</style>
