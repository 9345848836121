<template>
  <td
    style="min-width:130px;max-width:130px;border: none;border-left:1px solid #eef1fe"
    class="row justify-center task-column-item"
  >
    <div v-if="shouldShowOnTask" class="flex-1">
      <div v-if="customField.type === 'dropdown'" class="full-height">
        <div
          class="app-text-normal text-align-middle row items-center justify-center q-px-sm"
          :style="[
            selectedOptionColor
              ? {
                  'background-color': selectedOptionColor,
                  color: 'white',
                  'font-weight': '500',
                }
              : { 'font-weight': '400' },
          ]"
        >
          <div class="text-truncate" v-tooltip>
            {{ selectedOptionLabel }}
          </div>
          <q-tooltip v-if="isTruncated">
            {{ selectedOptionLabel }}
          </q-tooltip>
        </div>
      </div>
      <div
        v-else-if="customField.type === 'text'"
        class="row items-center custom-field-font text-align-middle justify-center q-px-sm"
      >
        <div class="text-truncate" v-tooltip>
          {{ CFNumberNoteTxt }}
          <q-tooltip v-if="isTruncated">
            {{ CFNumberNoteTxt }}
          </q-tooltip>
        </div>
      </div>
      <div
        v-else-if="customField.type === 'checkbox'"
        class="row items-center custom-field-font text-align-middle justify-center"
      >
        <q-icon :name="$icons.matCheckCircle" style="color:#919ca8" size="xs" />
        <!-- <div class="q-ml-xs">
          {{ customField.title }}
        </div> -->
      </div>
      <div
        v-else-if="customField.type === 'number'"
        class="row items-center custom-field-font text-align-middle justify-center q-px-sm"
      >
        <div class="text-truncate" v-tooltip>
          {{ numberWithCommas(CFNumberNoteTxt) }}
          <q-tooltip v-if="isTruncated">
            {{ numberWithCommas(CFNumberNoteTxt) }}
          </q-tooltip>
        </div>
      </div>
      <div
        v-else-if="customField.type === 'date'"
        class="row items-center text-truncate custom-field-font text-align-middle justify-center"
      >
        <div>{{ CFDateTimeValue }}</div>
      </div>
    </div>
  </td>
</template>

<script>
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
export default {
  props: ["taskdata", "column"],
  data() {
    return {
      isTruncated: false,
    };
  },
  methods: {
    numberWithCommas(number) {
      return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    selectedOptionColor() {
      const dropdpownCFSelection = this.taskdata.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionColor = dropdpownCFSelection
        ? this.customField.customFieldOption.find(
            (o) => o.id === dropdpownCFSelection.c_f_o_id
          ).colorcode
        : null;
      return selectedOptionColor;
    },
    selectedOptionLabel() {
      const dropdpownCFSelection = this.taskdata.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionLabel = dropdpownCFSelection
        ? this.customField.customFieldOption.find(
            (o) => o.id === dropdpownCFSelection.c_f_o_id
          ).label
        : null;
      return selectedOptionLabel;
    },
    isChecked() {
      const checkboxCFSelection = this.taskdata.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text
        ? checkboxCFSelection.option_text.toLowerCase() === "true"
        : false;
    },
    CFDateTimeValue() {
      if (this.customField.type === "date") {
        const checkboxCFSelection = this.taskdata.customFieldOptionSelection.find(
          (cfs) => cfs.c_f_id === this.customField.id
        );
        const dateLabel =
          checkboxCFSelection &&
          checkboxCFSelection.option_text &&
          format(fromUnixTime(checkboxCFSelection.option_text), "MMM dd");
        return dateLabel;
      }
      return null;
    },
    CFNumberNoteTxt() {
      const checkboxCFSelection = this.taskdata.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text;
    },
    shouldShowOnTask() {
      if (this.customField.is_active && this.customField.show_in_task) {
        if (this.customField.type === "dropdown" && this.selectedOptionLabel) {
          return true;
        } else if (this.customField.type === "checkbox" && this.isChecked) {
          return true;
        } else if (
          (this.customField.type === "number" ||
            this.customField.type === "text") &&
          this.CFNumberNoteTxt
        ) {
          return true;
        } else if (this.customField.type === "date" && this.CFDateTimeValue) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    customField() {
      return this.$api.getEntity("customField", this.column.c_f_id);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-align-middle {
  height: 87%;
  margin-top: 0.23rem;
  text-align: center;
  line-height: 45px;
  width: 100%;
  font-size: 14px;
}
.custom-field-font {
  font-size: 12px;
  font-weight: 500;
  color: #677484 !important;
}
</style>
