var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"row justify-center task-column-item",staticStyle:{"min-width":"130px","max-width":"130px","border":"none","border-left":"1px solid #eef1fe"}},[(_vm.shouldShowOnTask)?_c('div',{staticClass:"flex-1"},[(_vm.customField.type === 'dropdown')?_c('div',{staticClass:"full-height"},[_c('div',{staticClass:"app-text-normal text-align-middle row items-center justify-center q-px-sm",style:([
          _vm.selectedOptionColor
            ? {
                'background-color': _vm.selectedOptionColor,
                color: 'white',
                'font-weight': '500',
              }
            : { 'font-weight': '400' },
        ])},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.selectedOptionLabel)+" ")]),(_vm.isTruncated)?_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.selectedOptionLabel)+" ")]):_vm._e()],1)]):(_vm.customField.type === 'text')?_c('div',{staticClass:"row items-center custom-field-font text-align-middle justify-center q-px-sm"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.CFNumberNoteTxt)+" "),(_vm.isTruncated)?_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.CFNumberNoteTxt)+" ")]):_vm._e()],1)]):(_vm.customField.type === 'checkbox')?_c('div',{staticClass:"row items-center custom-field-font text-align-middle justify-center"},[_c('q-icon',{staticStyle:{"color":"#919ca8"},attrs:{"name":_vm.$icons.matCheckCircle,"size":"xs"}})],1):(_vm.customField.type === 'number')?_c('div',{staticClass:"row items-center custom-field-font text-align-middle justify-center q-px-sm"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.CFNumberNoteTxt))+" "),(_vm.isTruncated)?_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.CFNumberNoteTxt))+" ")]):_vm._e()],1)]):(_vm.customField.type === 'date')?_c('div',{staticClass:"row items-center text-truncate custom-field-font text-align-middle justify-center"},[_c('div',[_vm._v(_vm._s(_vm.CFDateTimeValue))])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }